<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet" ref="list"></app-list>
  </div>
</template>
<script>
  let validMobile = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("电话号码格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "商户列表（房东）",
          search: [{
              key: "orgType",
              label: "商户类型",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "个人",
                    value: "2"
                  },
                  {
                    label: "企业/单位",
                    value: "1"
                  }
                ]
              }
            },
            {
              key: "realName",
              label: "管理员姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "mobile",
              label: "手机号码",
              rules: [{
                validator: validMobile,
                trigger: ['blur']
              }]
            },
            {
              key: "auditStatus",
              label: "账号状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "未审核",
                    value: "1"
                  },
                  {
                    label: "已审核",
                    value: "2"
                  },
                ]
              }
            }
          ],
          columns: [{
              label: "商户编号",
              key: "orgCode",
              on: row => {
                _this.$router.push("/main/landlord/detail/show/" + row.landlordUserId + '/' + row.orgId);
              }
            },
            {
              label: "商户名称",
              key: "orgName"
            },
            {
              label: "商户类型",
              key: "isVerifyStr"
            },{
              label: "管理员姓名",
              key: "realName",
            },
            {
              label: "管理员性别",
              key: "gender",
            },
            {
              label: "手机号码",
              key: "mobile"
            },
            {
              label: "现居城市",
              key: "city"
            },
            {
              label: "详细地址",
              key: "address"
            },
            {
              label: "房源个数",
              key: "houseCount"
            },
            {
              label: "提交认证时间",
              key: "verifiedDate"
            },
            // { label: "身份证号码", key: "idcardno" },
            // { label: "紧急联系电话", key: "emergencyPhoneNum" },
            {
              label: "身份审核",
              key: "banFlagBoo",
              type: "switch",
              width: 180,
              opt: {
                activeText: "通过",
                inactiveText: "不通过",
                on(form) {
                  return _this.post(
                      "/user-service/user/modifyUserAuthState", {
                        orgId: form.orgId,
                        userId: form.landlordUserId,
                        authState: !form.banFlagBoo ? 2 : 1
                      }, {
                        isUseResponse: true,
                        isNotShowError: true,
                        operatorFlage: true,
                        operateName: !form.banFlagBoo ? "房东审核/通过" : "房东审核/不通过",
                      }
                    )
                    .then(res => {
                      if (res.data.code == 0) {
                        return {
                          state: true
                        };
                      }
                    })
                    .catch(res => {
                      return {
                        state: false,
                        msg: res.data.msg
                      };
                    });
                }
              }
            }
          ],
          buttons: [{
              type: 1,
              name: "审核",
              on(data) {
                _this.$router.push(
                  "/main/landlord/detail/edit/" +
                  data.data.landlordUserId + "/" +
                  data.data.orgId +
                  "?disabled=true&isAuto=true"
                );
              }
            },
            {
              type: 2,
              color: "danger",
              name: "删除",
              on(data) {
                if(data.list.length>1){
                  return _this.$message({
                    type: "warning",
                    message: "不支持多项删除"
                  })
                }
                const h = _this.$createElement;
                    _this.$msgbox({
                      title: "删除提示",
                      message: h("p", null, [
                        h(
                          "span", {
                            style: "color: #333;font-size: 16px;margin-left:98px"
                          },
                          "确定要删除房东账号吗？"
                        ),
                        h(
                          "div", {
                            style: "color: #666;font-size: 14px;margin-left:98px"
                          },
                          "如房东账号下存在历史订单数据则会同步清空。"
                        ),
                      ]),
                      showCancelButton: true,
                      confirmButtonText: "确定",
                      cancelButtonText: "取消"
                    }).then(() => {
                    var dto = {
                      orgId: data.list[0].orgId
                    };
                    _this.post("/landlord-service/org-info/v1/delete", dto).then(function() {
                        _this.$message({
                          showClose: true,
                          message: "删除成功!",
                          type: "success"
                        });
                        data.refresh();
                      });
                  }).catch(function() {});
              }
            }
          ]
        },
        roomList: []
      };
    },
    created() {
      console.log("user-list created!!");
    },
    activated() {
      console.log("user-detail created!!");
      let _this = this;
      if (_this.cache.getLS("userInfo")["roleType"] == "8") {
        _this.opt.columns.forEach((item, index) => {
          if (item.key == 'banFlagBoo') {
            _this.opt.columns.splice(index, 1);
          }
        })

      }
      _this.getMenuName(this.$route.path)
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("landlord-service/org-info/v1/query/page", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.banFlagBoo = item.auditStatus == "2" ? true : false;
            item.gender = item.gender == "1" ? "男" : "女";
            item.idcardno = item.idcardno ?
              item.idcardno.substring(0, 2) +
              "******" +
              item.idcardno.substring(
                item.idcardno.length - 2,
                item.idcardno.length
              ) :
              "";
            item.orgCode = item.orgCode || "--";
            item.orgName = item.orgName || "--";
            item.isVerifyStr = item.orgType == 1 ? "企业/单位" : "个人";
          }
          opt.cb(res.data);
        });
      },
      Oncancel() {
        this.dialogVisible = false;
        this.dialogList.door = false;
        this.dialogList.home = false;
        this.dialogList.room = "";
      }
    }
  };
</script>
<style lang="scss">
  .user-list {
    .el-dialog__body {
      padding: 0px 20px 30px 20px !important;
    }

    .alertTilte {
      .ul_div {
        display: flex;
        line-height: 40px;
        font-family: "微软雅黑";
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }

      .ul_li_one {
        width: 240px;
        height: 40px;
        text-align: right;
      }

      .ul_li_scond {
        width: 160px;
        height: 40px;
        text-align: center;
      }

      .ul_li_thrwe {
        width: 310px;
        text-align: left;

        .img_div {
          width: 70px;
          height: 70px;
          float: left;
          border: 1px solid #dcdfe6;
          margin-right: 5px;
          margin-bottom: 5px;

          img {
            width: 100%;
            height: 100%;
            vertical-align: top;
          }
        }

        .ul_li_select {
          width: 310px;
        }
      }
    }
  }
</style>